.flex-box {
    min-height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    background-color: white;
}

.form-group {
    margin-bottom: 0;
}

.login-form .label {
    width: 100%;
    margin-top: 45px;
    padding-bottom: 25px;
    min-height: 45px;
}

.passwd-box {
    position: relative;
}

.toggle_pwd {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    line-height: 1.5;
    padding: 10px;
    right: 10px;
}


.login-form {
    position: relative;
    width: 100%;
    max-width: 535px;
}


.special-form-control {
    padding: .67857143em 1em;
    width: 350px;
    border-radius: .28571429rem;
    outline: none;
    text-indent: 10px;
    color: #6e7393;

    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 1rem;
}

.login-box {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.inicio-sesion__contenedor {
    width: 100%;
    max-width: 605px;
    min-height: calc(100vh - 100px);
    padding-top: 10px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 500px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    box-shadow: 0 2px 2px -1px #0003, 0 1px 2px 0 #00000024, 0 1px 3px 0 #00000080;
    padding-bottom: 2rem;
}


.image-box {
    background-repeat: no-repeat;
    background-position: 0;
    background-size: cover;
    background-image: url(../../commons/images/fondoLogin.jpg);
    width: calc(100vw - 605px);
    min-height: calc(100vh - 100px);
}

.inicio-sesion__div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    gap: 1rem;
    height: 100%;
    background-color: rgb(255, 255, 255);
}


.inicio-sesion__input {
    padding: .67857143em 1em;
    width: 350px;
    border-radius: .28571429rem;
    border: none;
    outline: none;
    text-indent: 10px;
    color: #6e7393;
}

.inicio-sesion__logo-imc {
    margin-top: 3rem;
    width: 100px;
    cursor: default
}


.inicio-sesion__boton {
    width: 350px;
    cursor: pointer;
    margin-top: 1rem;
    border: none;
    color: white;
    border-radius: .28571429rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    background-color: #814C33;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #00000080;
}

.inicio-sesion__boton:hover {
    background-color: rgb(121, 121, 121);
}

.inicio-sesion__pantalla {

    width: 100vw;
    height: 100vh !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.sign-in-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 6rem;
}

.sign-in-fields {
    display: flex;
    align-content: space-around;
    flex-direction: column;
    margin-bottom: 1rem;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--dark);
    border-color: var(--dark);
}