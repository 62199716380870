.upload-label {
    margin-bottom: 0rem;
}

.upload-name {
    border: none;
    line-height: 0;
    padding: .37857143em 1em;
    padding-left: calc(var(--bs-gutter-x) * .5);
    outline: none;
    color: #6e7393;
    width: 20rem;
    font-size: 0.82rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.upload-inputFile {
    width: 20rem;
    min-width: 20rem;
}

.fakeInput {
    text-indent: 10px;
    font-weight: 500 !important;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 2.105rem;
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.686);
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnX {
    border: 1px solid #ced4da;
    border-radius: 5px;
    border-left: none;
    height: 2.105rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    background-color: white !important;
    color: #575962 !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    justify-content: flex-end;
}

.btnX:hover {
    border-color: #ced4da !important;
}

.btnX:active {
    border-color: #ced4da !important;
}

input[type="file"] {
    display: none;
}

.upload-buttom {
    width: 8.8rem;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    background-color: var(--secondary);
}

.inputData {
    text-indent: 10px;
    font-weight: 500 !important;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 2.105rem;
    display: flex;
    justify-content: flex-start;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.686);
}

.dataFileSelector {
    display: flex;
    align-items: center;
}

.upload-input-typeahead3 {
    border: 1px solid rgba(128, 128, 128, 0.671);
    border-radius: 5px;
}

.input-form-typeahead3 {
    border-radius: .28571429rem;
    outline: none;
    text-indent: 10px;
    color: #6e7393;
    display: block;
    width: 100%;
    line-height: 0.875rem;
    font-size: 0.82rem;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: white;
    padding-left: 1.8rem;
}

.input-form-typeahead3:focus {
    background-color: white !important;
    color: rgba(0, 0, 0, 0.686);
}

.inputFile {
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
}