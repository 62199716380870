.no-thead {
    border-collapse: separate;
    border-spacing: 0 1rem;
}

.no-thead thead {
    display: none
}

.no-thead tr {
    background: var(--gray);
    background-color: var(--gray);
}

.table-basic {
    border-collapse: separate; /*Quitar para separación dentro de tabla */
    border-spacing: 0 0.5rem;
}

.table-basic tr {
    background: var(--gray);
    background-color: var(--gray);
    outline: 0;
    border: 0;
}


.table-basic thead tr {
    background: #fff;
    background-color: #fff;
}

.table-borderless td,
.table-borderless tr,
.table-borderless th {
    border: 0;
}

td.reset-expansion-style {
    background-color: white;
    border-left: 1px solid var(--gray-line-table);
}

.table-basic th {
    border-bottom: 1px solid var(--secondary);
}

.page-item.active .page-link {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.page-link {
    color: var(--secondary);
}

.page-link:hover {
    background-color: var(--gray);
}

.page-link:focus {
    box-shadow: none;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    box-shadow: none;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.table>:not(caption)>*>* {
    padding: 0.25rem 0.25rem;
}

.actions {
    display: flex;
    justify-content: end;
}

.actions-home {
    right: 0;
    top: 2.5rem;
    padding-right: 3rem;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.filter-label {
    width: 5rem !important;
}

.filter-label>* {
    padding: 0 !important;
    margin: 0 !important;
}

.play-button {
    border: 0;
    background: none;
}

.table-hover td {
    vertical-align: middle;
}

.table-hover td:first-child,
.table-hover th:first-child {
    padding-left: 0.675rem
}

.table-hover td:last-child,
.table-hover th:last-child {
    padding-right: 0.675rem
}

.eye {
    font-size: 1rem;
}

.nErr {
    color: var(--danger)
}

.succes {
    color: var(--success)
}

.btn-actions {
    padding: 0 !important;
}

.expandBox{
    padding-left: 1rem;
}

.expandDataValue{
    padding-left: 0.4rem;
}

.pending {
    color: orange
}

.processing {
    color: orange
}

.sr-only {
    display: none !important;
}

.sentence {
    margin-left: 14px;
    width: 120px;
}

.sentence-initial-table {
    width: 90px !important;
}

.data-table-initial {
    display: flex;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    flex-wrap: wrap;
    align-items: flex-start;
}

.header-table span {
    display: table-footer-group
}

.header-table .filter-label {
    display: table-header-group
}

.text-filter {
    width: 75%;
    line-height: 0;
    margin-bottom: 1rem;
    height: 1.5rem;
    background-color: white;
}

.text-filter:focus {
    background-color: white;
}

#batch-table .reset-expansion-style {
    background-color: white;
}

.icon {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin-left: 0.625rem;
}

.circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray);
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid var(--gray-line-table);
    border-radius: 100%;
}

.color-icon {
    color: var(--primary)
}

.color-icon:hover {
    color: var(--secondary)
}

.pi {
    font-family: primeicons;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tam {
    width: 2.5rem;
}

#pageDropDown {
    background-color: var(--gray);
    border-color: var(--gray-dark);
    color: black;
}

.no-result {
    margin-top: 1rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.cell-breakWord {
    word-wrap: break-word;
    width: 100%
}

.data-cell-breakWord {
    width: calc(100% - 90px) !important;
    word-wrap: break-word;
}