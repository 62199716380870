.actions-comment {
    position: absolute;
    left: 25rem;
    margin-top: 0.25rem;
    padding-left: 0.95rem;
}

.msg-comment {
    padding-top: 1.5rem;
}

.msg-box {
    font-weight: normal;
}

.label-new-comment {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-weight: 700;
}