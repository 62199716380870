@media (max-width: 1200px) {
  .container-fluid {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .logo {
    top: 0.55rem;
    block-size: 2.7rem;
  }

  .actions-home {
    position: absolute;
  }

  .ps-4rem {
    padding-left: 2rem;
  }

  .bg-title {
    width: 16rem;
  }

  .secondLane {
    font-size: 1.15rem;
  }

  .section-block {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .special-block {
    margin-left: -2.8rem;
    margin-right: -2.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .special-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .barra-navegacion_acciones {
    gap: 0.75rem;
  }

  .actions-home {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .undefined .btn {
    text-align: right;
  }


  .input-pr-2 {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .container-fluid {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .section-block {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .special-block {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    left: auto;
  }

  .section {
    height: auto;
    padding-bottom: 0.5rem;
    text-align: right;
  }

  .special-content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .actions-home {
    position: sticky;
  }

  .actions {
    padding-right: 0rem;
  }

  .aux-scroll .principal-title {
    display: block;
  }

  .principal-title .actions {
    margin-top: 0.75rem;
  }
}


@media (max-width: 905px) {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-block {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .special-block {
    margin-left: -1.8rem;
    margin-right: -1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .special-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .bg-title {
    width: 12rem;
  }

  .ps-4rem {
    padding-left: 1rem;
  }

  .secondLane {
    font-size: 1.1rem;
    margin-right: 0;
  }

  .button-state,
  .button-state:hover,
  .button-state:active,
  .button-state:focus,
  .button-state:focus {
    margin-right: 1rem;
  }
}

@media (max-width: 575px) {

  .state-end .width-7rem,
  .state-end .width-10rem,
  .state-end .width-8rem {
    width: auto;
  }

  .nact {
    min-width: auto;
  }

  .text-bg-initial,
  .text-bg-pending,
  .text-bg-success,
  .text-bg,
  .text-bg-ko {
    margin-left: 0px;
    margin-right: 0px !important;
  }


  .state-end .section,
  .state-end .section .long-button button,
  .state-end .section .long-button,
  .eva .section .long-button,
  .eva .section .long-button button {
    min-width: auto;
    padding-bottom: 0;
    padding-top: 0;
  }

  .dropdown-toggle::after {
    vertical-align: 0px;
  }

  .plus-botton {
    font-size: 1.2rem;
    height: 31.7px;
  }

  .width-7rem .badge,
  .width-7rem .badge {
    margin-left: 0.15rem;
    margin-right: 0.15rem !important;
  }

  .myAcordion .box {
    height: auto;
    min-height: 3rem;
  }

  .button-state,
  .button-state:hover,
  .button-state:active,
  .button-state:focus,
  .button-state:focus {
    margin-right: 0.5rem;
  }

}

@media (max-width: 450px) {
  hr {
    display: none;
  }

  .logo {
    top: 0.65rem;
    block-size: 2.5rem;
  }

  .bg-title {
    width: 11rem;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .section-block {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .actions-home {
    position: absolute;
  }

  .special-block {
    margin-left: -3.8rem;
    margin-right: -3.8rem;
  }

  .special-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}