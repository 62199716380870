.blueNavbar {
    padding: 0;
    border-bottom: 6px solid var(--gray);
    background-color: var(--primary);
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1000;
    color: white;
    margin: 0;
    height: 3.75rem;
}

.blueNavbar p {
    margin-bottom: 0;
}

.secondLane {
    font-size: 1.25rem;
    color: white;
    text-align: left;
    margin-right: 55px;
}

.secondLane .subtitle {
    color: var(--secondary);
    font-size: 0.8rem;
    text-align: right;
}

#basic-nav-dropdown {
    color: white;
    font-size: 1.2rem;
    border-left: none;
    margin-right: 1rem;
}


.dropdown-subtitle {
    cursor: default;
    width: 100%;
    padding: 4px;
    padding-left: 10px;
    border-top: #c8c8c8 1px solid;
    border-bottom: #c8c8c8 1px solid;
    background-color: #f5f5f5;
    text-align: left;
}

.dropdown-menu li span:first-child {
    font-weight: bold;
}

.dropdown-title {
    cursor: default;
    padding-left: 24px;
    padding-bottom: 9px;
}

nav.navbar.fixed-top>div>div>ul#pills-tab {
    float: right;
}

.home-icon {
    cursor: pointer;
    height: 1.5rem;
}

.user-account-first-level {
    border: none;
}

.user-color {
    color: #7b7b7b !important;
}

.user-account-nav-tabs .user-account-nav-item.show .user-account-nav-link,
.user-account-nav-tabs .user-account-nav-link.active {
    color: #ffffff;
    cursor: pointer;
}

.user-account-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.first-level>li>a:hover {
    border: none;
    color: rgb(42, 114, 140);
    background: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.8rem 0;
    margin: 0;
    font-size: .85rem;
    text-align: left;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 2px;
    box-shadow: 0 4px 18px rgb(0 0 0 / 50%);
}

.title-options {
    font-size: 1rem;
    padding-right: 0.5rem;
}

.user-list {
    position: absolute;
    right: 0;
    width: 15rem;
    margin-right: 3rem;
}

.user-element {
    display: flex;
}

.fill-white {
    block-size: 1.25rem;
    fill: white;
}




.ps-4rem {
    padding-left: 4rem;
}

.bg-title {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    padding-bottom: 1.5rem;
    width: 20rem;
}

.logo {
    top: 0.4rem;
    block-size: 3rem;
}