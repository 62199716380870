.modal-usuario {
  position: fixed;
  width: 20rem;
  height: 23rem;
  padding-top: 1.5rem;
  top: 55px;
  right: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
  outline: none;
  border-radius: 3%;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
}

.titulo-pagina__icono {
  width: 30px;
}

.titulo-pagina__boton {
  background-color: white;
  padding: .5rem;
  border: 1px solid grey;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.eliminar-background>div {
  background-color: transparent !important;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #003366;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);

}

.iniciales {
  font-size: 3.5rem;
  color: white;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.nombre {
  font-size: 1rem;
  color: black;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}

.correo {
  font-size: 1rem;
  color: gray;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}

.botonCambiarPasswd {
  margin-top: 1rem;
  background-color: white;
  border: 2px solid gray;
  color: gray;
  border-radius: 2rem;
  padding: 0.5rem 0rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  width: 75%;
}

.botonCerrarSesion {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: rgb(121, 121, 121);
  border: none;
  border: 2px solid rgb(121, 121, 121);
  color: white;
  border-radius: 2rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}

.line {
  border: 0;
  clear: both;
  display: block;
  width: 90%;
  background-color: var(--primary);
  height: 1px;
  margin: 1.5rem 0rem 0.5rem 0rem;
}

.barra-navegacion_acciones {
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.barra-navegacion__separador {
  align-self: center !important;
  width: 3px !important;
  height: 23px !important;
  border-radius: 5px;
  background: white linear-gradient(45deg, white 0%, white 100%) repeat scroll 0 0;
}

.barra-navegacion__link {
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  position: relative;
}