.react-calendar__tile--now {
    background-color: var(--gray);
}

.react-calendar__month-view__days__day--weekend {
    color: var(--secondary);
}

.react-calendar__tile--active {
    background: var(--secondary);
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--secondary);
    color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--secondary);
    color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--secondary);
    color: white;
}

.border-color-secondary {
    border-color: var(--secondary);
}

.lens-clear {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    fill: var(--secondary);
}