.nav-link {
    font-size: 1rem;
    font-weight: 600;
    color: black;
}

.objective-image {
    margin-bottom: 0.1rem;
    margin-right: 0.5rem;
    block-size: 1rem;
    top: 0.5rem;
}

.block-size {
    block-size: 1rem;
}

.tab-pane {
    padding: 1.25rem 0.8rem;
}

.col-primary {
    background-color: var(--gray-back);
    border-left: 1px solid var(--gray-line-table);
    border-top: 1px solid var(--gray-line-table);
    font-weight: 645;
}

.col-primary-end {
    background-color: var(--gray-back);
    border-bottom: 1px solid var(--gray-line-table);
    border-top: 1px solid var(--gray-line-table);
    border-left: 1px solid var(--gray-line-table);
    font-weight: 645;
    text-align: left;
}

.col-secondary {
    border-left: 1px solid var(--gray-line-table);
    border-top: 1px solid var(--gray-line-table);
    border-right: 1px solid var(--gray-line-table);
}

.col-secondary-end {
    border-bottom: 1px solid var(--gray-line-table);
    border-left: 1px solid var(--gray-line-table);
    border-right: 1px solid var(--gray-line-table);
    border-top: 1px solid var(--gray-line-table);
}

.col-secondary-bottom {
    border-bottom: 1px solid var(--gray-line-table);
    border-left: 1px solid var(--gray-line-table);
    border-right: 1px solid var(--gray-line-table);
}


.answer-color {
    background-color: var(--gray);
    color: black !important;
    line-height: 0.82rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    border-left: 5px solid var(--secondary);
    color: var(--secondary);
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
    border-left-color: var(--secondary);
}

.nav-tabs .nav-link {
    border: none;
}

.nav-link {
    transition: none;
}

.section-evaluate {
    margin: 1.8rem -0.8rem;
}

.nav-item button {
    border: none;
}

.nav-tab .nav-link {
    border: 1px solid transparent !important;
}

.answer>div {
    align-self: center;
    flex: 0 0 auto;
}

.answer {
    display: flex;
    align-items: center;
}

.question-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0 !important;
}

.width-50 {
    width: 50%;
}

.width-45 {
    width: 45%;
}

.width-100 {
    width: 100%;
}

.question-block>* {
    align-self: center;
    flex: 0 0 auto;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}

.nav-item>button:not(.active) {
    border-left: 5px solid #DDD;
    color: #000 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

}

.question-input {
    font-size: 0.8rem !important;
    line-height: 0.8rem !important;
    padding-left: 10px !important;
    text-indent: 0;
    height: 1.796rem !important;
}

.question {
    padding-left: 0 !important;
}


.result {
    font-size: 1rem;
    padding-left: 1rem;
    border-left: 5px solid var(--secondary);
    font-weight: 650;
}

.check {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.82rem;
    background-color: var(--success);
    border-radius: 5px;
    justify-items: center;
}

.tam-row {
    height: 50px;
    margin-top: 40px;
    display: flex;
    align-items: center;
}



.spinner-border {
    margin: 7rem auto;
    height: 7rem;
    width: 7rem;
}

.eval-section {
    display: flex;
    justify-content: space-between;
}

.mt-4>.spinner-border {
    margin: 3rem auto;
    height: 3rem;
    width: 3rem;
}

.label-field-18 {
    width: 18rem;
}

.label-field-35 {
    width: 35rem;
}


.info-field {
    width: 2.5rem;
}

.form-field {
    width: 12rem;
    min-width: 12rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.form-units {
    width: fit-content;
    font-style: italic;
    padding-left: 0 !important;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.686);
}

.myAcordion {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.myAcordion>div {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.myAcordion>div>h2>button {
    height: 3rem;
    width: 100%;
    background-color: #EEEEEE !important;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    font-size: 0.90rem;
    margin-left: 0 !important;
}

.myAcordion>div>h2>button::after {
    margin-top: 0px;
}

.myAcordion>div>h2 {
    height: 3rem;
}

.myAcordion>.row {
    margin-left: 0;
    margin-right: 0;
}

.myAcordion-label {
    flex: 0 0 auto;
    width: 75%;
}

.myAcordion-status {
    flex: 0 0 auto;
    width: 115px;
    margin-left: 0.5rem;
}

.form-select:disabled {
    background-color: #d4d4d4 !important;
}

.color-succes {
    color: var(--success);
    font-size: 1rem;
    margin-bottom: 0.15rem
}

.color-fail {
    color: var(--danger);
    font-size: 1rem;
    margin-bottom: 0.15rem
}

.plus-size {
    font-size: 1.15rem;
}

.icon-acordion {
    margin-right: 2px !important;
}

.color-pending {
    color: orange;
    font-size: 1rem;
    margin-bottom: 0.15rem
}

.m-0-5 {
    margin-left: 0.5rem;
}


.accordion-item {
    border: none;
}

.accordion-button:focus {
    border-color: #dee2e6;
    box-shadow: none;
}


.accordion-button:not(.collapsed) {
    background-color: none;
    color: black;
    box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.colorBlack > li >button{
    color: #000 !important;
    cursor:text !important
}

.aligmentResults-block{
    margin-top: 1rem;
}

.evaluationTitle-minwidth{
    min-width: 195px !important;
}

.summaryAligment-badge {
    cursor:text !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}

.evaluationAligment-badge {
    cursor:text !important;
}

.summaryAligment-title {
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid var(--secondary);
    font-weight: 600;
    display: flex;
    align-items: center;
    color:var(--secondary);
}

.summaryAligment-titleContainer{
    display: flex;
    padding-left: 1rem;
}

.summary-block{
    padding-left: 0.9rem;
    padding-right: 0.9rem;
}

.summaryAligment-subtitle {
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    margin-right: 1rem;
    font-weight: 650;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summaryAligment-detail {
    display: flex;
    align-items: center;
}

.pr{
    padding-right: 2rem;
}

.summaryResult-title {
    font-size: 0.82rem;
    padding-left: 0rem;
    font-weight: 645;
}

.summaryResult-subtitle {
    font-size: 0.82rem;
    padding-left: 0.5rem;
    font-weight: 645;
}

.summaryResult-detail {
    padding-left: 0rem;
}

.summaryResult-Dnshdetail {
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.div-end {
    position: absolute;
    right: 3rem;
    top: 1.3rem;
}

.accordion-button {
    transition: none;
    min-height: 3rem;
    height: auto !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.border-result {
    border: 0.05px solid black
}

.is-disable {
    pointer-events: none;
    opacity: 0, 7;
}

.is-not-disabled{
    pointer-events: auto !important;
    opacity: 0, 7;
}

.info-icon{
    margin-right: 1rem;
    margin-top: 0;
    margin-bottom: 0;
}

.info-note{
    padding: 0.5rem;
    background-color: rgb(250, 226, 175,0.5);
    display:flex;
    justify-content: space-between;
}

#evaluation {
    position: relative;
}

.myAcordion-eval {
    width: calc(100% - 150px);
}

@media (max-width: 1327px) {
    .form-field {
        width: 13rem;
        min-width: 13rem;
    }

    .row {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .question-block {
        padding-bottom: 10px;
    }
}

@media (max-width: 1200px) {
    .div-end {
        right: 1rem;
    }
}

.myAcordion-label {
    width: calc(100% - 75px - 4rem);
}

.margin-md {
    text-align: right;
    padding-right: 4rem;
}

.row .margin-md {
    width: auto;
}

@media (max-width: 940px) {
    .div-end {
        top: 3.25rem;
    }

    .nav-tabs {
        margin-bottom: 1rem;
    }
}

@media (max-width: 905px) {
    .div-end {
        right: 0.1rem;
    }
}

@media (max-width: 790px) {
    .div-end {
        top: 5.75rem;
    }
}

@media (max-width: 767px) {
    .tabs-home .tab-content .tab-pane {
        padding-top: 0px;
    }
}

@media (max-width: 575px) {
    .form-field {
        width: 12rem;
        min-width: 12rem;
    }

    .question-block {
        display: block;
    }

    .question {
        width: 100% !important;
    }

    .question-block>* {
        padding-left: 0px;
    }
}

@media (max-width: 531px) {
    .div-end {
        top: 8rem;
    }

    .question {
        width: 100% !important;
    }

    .width-50 {
        margin-bottom: 0.75rem;
    }
}