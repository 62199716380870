:root {
  --primary: #001B45;
  --bs-primary-rgb: 0, 27, 69;
  --white: #FFFFFF;
  --gray-line-table: #dee2e6;
  --gray: #f2f2f2;
  --gray-dark: #6c757d;
  --gray-back: #EEEEEE;
  --secondary: #4472C4;
  --link: #0d6efd;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

/*GENERAL*/

body {
  overflow: auto !important;
  padding-right: 0rem !important;
  font-size: 0.82rem;
  background-color: var(--gray);
}

.section-block {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0;
  justify-content: space-between;
  background-color: var(--white);
}

.special-block {
  background-color: var(--gray);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: -1.5rem;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.special-content {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

}

.special-content .content {
  padding: 1.25rem 0.8rem;
}

.first-block {
  margin-top: -1.5rem;
  padding-top: 1.25rem;
}

.color-white {
  color: white;
}

.color-text-drop {
  color: black;
}

.color-primary {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.color-gray {
  color: var(--gray-dark) !important;
}

.color-text-drop:hover {
  color: white;
}

.plus {
  font-weight: 800;
}

.arrow {
  font-size: 7px;
}

.fade-panel {
  position: fixed;
  z-index: 100000000;
  padding-top: 100px;
  left: 0px;
  right: 0px;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.loading-content {
  height: 5rem;
  width: 5rem;
}

.loading-text {
  color: white;
  position: absolute;
  margin-bottom: 7rem;
}

/*INICIO BLOQUE TABLAS*/

label {
  padding-right: 1rem
}

a {
  text-decoration: none;
}

td {
  background-color: var(--gray);
  padding: 1rem;
}

.section-title {
  color: var(--secondary);
  border-bottom: 0.05px solid var(--primary);
  font-size: 0.9rem;
  font-weight: bold;
}

.section-title-modal {
  color: var(--secondary);
  font-size: 0.9rem;
  font-weight: bold;
}

/*FIN BLOQUE TABLAS*/

.App {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  margin-top: auto;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;
  font-family: var(--font);
}


.header-title {
  color: var(--secondary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
}



.body-content {
  padding-top: 4.75rem;
  min-height: calc(100vh - 3rem);
  /*tamañaño footer*/
  padding-bottom: 1rem;
}

.border-body {
  border: 5.6px solid var(--gray);
  -webkit-box-shadow: 10px 10px 12px -12px #000000BF;
  -moz-box-shadow: 10px 10px 12px -12px #000000BF;
  box-shadow: 10px 10px 12px -12px #000000BF;

}

.button-primary {
  margin-bottom: 0.5rem;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 5px;
}

.button-state,
.button-state:hover,
.button-state:active,
.button-state:focus,
.button-state:focus {
  margin-bottom: 1rem;
  margin-right: 2rem;
  color: black;
  background-color: white !important;
  border-color: var(--primary);
  border-radius: 2rem;
}

.width-7rem {
  width: 7rem;
}

.width-8rem {
  width: 8rem;
}

.width-10rem{
  width: 10rem;
}

.form-select:focus {
  box-shadow: none;
  border: 0;
}

.form-control,
.form-select {
  background-color: var(--gray);
  font-size: 0.82rem;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.input-form-typeahead {
  padding: .37857143em 1em;
  border-radius: .28571429rem;
  outline: none;
  text-indent: 10px;
  color: #6e7393;
  display: block;
  width: 100%;
  line-height: 0.875rem;
  font-size: 0.82rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.UE {
  font-size: 1.75rem;
  font-weight: 400;
}

.explorer {
  font-size: 1.25rem;
  font-style: italic;
}

.taxonomy {
  font-size: 1.25rem;
  font-weight: 600;
}

.bg-gray {
  background-color: var(--gray);
}

.bg-primary {
  background-color: var(--primary);
}

footer {
  width: 100%;
}

::-ms-reveal {
  display: none;
}

.text-bg-success {
  background: var(--success);
  border-radius: 50%;
  color: transparent;
  height: 1rem;
  width: 1rem;
  margin-left: 0.625rem;
}
.h0{
  height: 17px !important;
}

.text-bg {
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-left: 0.625rem;
}

.text-bg-ko {
  background: var(--danger);
  border-radius: 50%;
  color: transparent;
  height: 1rem;
  width: 1rem;
  margin-left: 0.625rem;
}

.text-bg-pending {
  background: orange;
  border-radius: 50%;
  color: transparent;
  height: 1rem;
  width: 1rem;
  margin-left: 0.625rem;
}

.text-bg-initial {
  background: var(--secondary);
  border-radius: 50%;
  color: transparent;
  height: 1rem;
  width: 1rem;
  margin-left: 0.625rem;
}


.btn {
  font-size: 0.82rem;
}


.box {
  height: 3rem;
  font-size: 0.90rem;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.w-45rem {
  width: 45rem;
}

.w-32 {
  width: 32%;
}

.state-end {
  display: flex;
  justify-content: flex-end;
}

.principal-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.no-hover-button button{
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  border: none;
  font-size: 0.88rem;
  transition: none;
}

.noPaddingX{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-hover-button::after{
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  border: none
}

.no-hover-button button.btn-primary:active, 
.no-hover-button button.btn-primary:hover, 
.no-hover-button button.btn-primary:focus, 
.no-hover-button button.btn-primary:active,
.no-hover-button.show>.btn-primary.dropdown-toggle{
  background-color: transparent;
  border: none;
}

.no-hover-button button.btn-primary::after{
  display: none;
}

.no-hover-button.btn-primary{
  background-color: transparent;
  border: none;
  color: #000;
  width: 100%;
  text-align: left;
}

a .no-hover-button.btn-primary:hover{
  color: var(--white)
}
a:hover .no-hover-button.btn-primary, .no-hover-button.btn-primary:hover{
  color: var(--white)
}

.info-note .h4.info-icon{
  font-size: 12px;
  cursor: pointer;
  margin: auto 5px;
}

.chevrodown{
  width: 17px;
  height: 17px;
}
.activity-selection.nav-link{
  border: none;
  border-left: 5px solid var(--secondary);
  background-color: var(--white);
  color: var(--secondary);
}

.p-35{
  padding-left: 35px;
  padding-right: 35px;
}

.font {
  font-weight: 600;
}

.icons-p {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #008a9b;
  font-size: 3rem;
}


.input-pr-2 {
  padding-right: 2rem;
}

.form-control:disabled {
  background-color: #d4d4d4;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.decoration {
  padding-bottom: 0.5rem;
  border-bottom-style: solid;
}

.nav-item>button:not(.active)>.decoration {
  border: none !important;
  color: #000 !important;
}

.tabs-home>ul>li>#noanim-tab-example-tab-informList {
  border-left: 2px solid #DDD !important;
}

.tabs-home>ul>li>button {
  border: none !important;
  padding-bottom: 0;
}

.divider-home {
  align-self: center !important;
  width: 3px !important;
  height: 23px !important;
  border-radius: 5px;
  background-color: #DDD;
}

.link-help .h6 {
  margin-bottom: 0px;
}