.options {
    display: flex;
    justify-content: flex-end;
}

.marginRight5px {
    margin-right: 5px;
}

.modal-body-input {
    padding-top: 0rem;
}

.btn-new {
    background-color: var(--secondary);
    color: white;
    border-color: var(--secondary);
}

.btn-new:hover,
.btn-new:active,
.btn-new:focus {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.mb-0-2 {
    margin-bottom: 0.2rem;
}

.section-title-modal>.btn-close {
    font-size: 0.7rem;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.msg-box {
    width: 100%;
    display: inline-block;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgba(0, 0, 0, .6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    background: rgb(237, 237, 237);
}

.docsRow {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: 0rem !important;
}