.section {
  min-width: 176px;
}

.sector {
  min-width: calc(100vh - 176px);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:focus {
  background-color: var(--secondary);
  box-shadow: none;
  border-color: var(--secondary);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef;
  color: var(--dark);
}

.long-button button,
.long-button {
  min-width: 10.94rem;
}

.mid-button button,
.mid-button {
  min-width: 7rem;
}

.btn-secondary {
  border-color: var(--secondary);
  background-color: var(--secondary);
}

.btn-secondary:hover {
  border-color: var(--primary);
  background-color: var(--primary);
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: white;
  background-color: var(--secondary);
}

.dropdown-item {
  font-size: 0.88rem;
}

@media (max-width: 575px) {
  .state-end .mid-button {
    min-width: 2rem;
  }
}