.dashboard-tableElement{
    display: flex;
    justify-content: space-between;
}

.dashboard-tableElementTrend{
    display: flex;
    width: 5rem;
    justify-content: flex-start;
}

.paddingLeftNone{
    padding-left: 0 !important;
}

.dashboard-tableElementTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.5rem;
    margin-right: 2.5rem;
    display: block;
    line-height: 1.5rem;
    height: 1.5rem;
    width: 90%;
}

.dashboard-tableElementTrendText{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
}

.text-danger{
    color: rgba(237,94,73, 1) !important;
}

.text-success{
    color: rgba(56, 199, 134, 1) !important;
}

.text-pending{
    color: rgb(249 183 28) !important;
}

.custom-data-label {
    max-width: 1rem; /* Establece el ancho máximo */
    white-space: normal; 
    word-wrap: break-word; 
    overflow-wrap: break-word;
    text-align: center; 
    display: block; 
  }
  

.dashboard-subtitle{
    text-anchor: start;
    font-size: 14px;
    font-weight: 500;
    color: #83838d;
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
    margin-bottom: 1.3rem;
}

.dashboard-tableElementTrendIcon{
    height: 1.5rem;
    width: 1.5rem;
}

.card{
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 0);
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: var(--bs-secondary-bg);
    --bs-card-bg: var(--bs-secondary-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.card-body{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    display: flex;
    flex: 1 1 auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--bs-card-color);
}

@media (min-width: 1200px) {
    .card-body {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
}

@media (max-width: 1200px) {
    .card-body {
        padding-left:  1.5rem ;
        padding-right: 1.5rem ;
    }    

    .dashboardCard{
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .lastDashboardCard{
        margin-right: 0.5rem !important;
    }
}

@media (max-width: 905px) {
    .card-body {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }    
}

.text-muted {
    color: #83838d !important;
    font-weight: 500;
}

.card-badge{
    width: 3rem !important;
    height: 2rem !important;
    margin: 0rem !important;    
    
    color: rgb(0, 27, 69) !important; 
    
    /* color:rgb(102, 121, 169) !important; */
    /* color:rgb(204, 229, 255) !important; */
}

.card-dangerBadgeSize {
    width: 3.625rem !important;
    height: 2.625rem !important;
}

.card-badgeBg{
    /* background-color: rgb(0, 27, 69) !important; */
    /* background-color: rgba(51, 76, 119, 0.85); */
    /* background-color: rgb(102, 121, 169) !important; */
    
    background-color: rgb(204, 229, 255) !important;
    
    border-radius: 0.50rem;
    border: 1px solid #eff5f6 !important;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



.card-btn > button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: calc(.94rem + 1.5em + -1px);
    width: calc(.94rem + 1.5em + -1px);
    padding: 0;
    border-radius: 50% !important;
    border: #eff5f6 !important;
    background-color: white !important;
}

.dashboard-info{
    text-anchor: start;
    font-size: 14px;
    font-weight: 500;
    color: #83838d;
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
}

.card-btn > button  {
    border-radius: 50% !important;
    border: 1px solid #eff5f6 !important;
}

.dashboardCard{
    background-color: var(--white);
    margin: 1rem;
    margin-right: 0rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .04);
    border: 0 solid #eff5f6;
    border-radius: 0.25rem;
}

.lastDashboardCard{
    margin-right: 1rem;
}

.section-Kpi{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0;
}

.card-btn > button > svg {
    color:black;
}

.dropdown-toggle::after {
    display:none;
}

.card-neutral {
    --bs-text-opacity: 1;
    font-size: 12px !important;
    background-color: rgba(255, 193, 7, .15) !important; /* Amarillo claro */
    color: rgba(255, 193, 7, 1) !important; /* Amarillo */
    display: flex;
    width: fit-content;
    align-items: flex-end;
}

.card-success{
    --bs-text-opacity: 1;
    font-size: 12px !important;
    background-color: rgba(56, 199, 134, .15) !important;
    color: rgba(56, 199, 134, 1) !important;
}
.card-neutral-icon {
    display: inline-block;
    vertical-align: bottom;
    --bs-text-opacity: 1;
    font-size: 22px;
    color: rgba(255, 193, 7, 1) !important;
    margin-right: -5px;
    margin-top: -7px;
    line-height: 15px;
}


.card-pending{
    --bs-text-opacity: 1;
    font-size: 12px !important;
    background-color: rgb(237 210 87 / 15%) !important;
    color: rgb(249 183 28) !important;
}

.card-danger{
    --bs-text-opacity: 1;
    font-size: 12px !important;
    background-color: rgba(237, 94, 73, .15) !important;
    color: rgba(237,94,73, 1) !important;
}