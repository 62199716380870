.lens-image {
    block-size: 0.9rem;
    position: absolute;
    top: 0.4rem;
    margin-left: 1rem;
    fill: var(--secondary)
}

.lens-image-error {
    block-size: 0.9rem;
    position: absolute;
    top: 0.4rem;
    margin-left: 1rem;
    fill: var(--danger)
}

.form-control:focus {
    background-color: var(--gray);
}

.input-form-typeahead {
    padding-left: 1.8rem;
    border: none;
    line-height: 0;
}

.btn-close {
    width: 0.3rem;
    height: 0.3rem;
    color: var(--secondary);
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM0NDcyYzQiPjxwYXRoIGQ9Ik0zNS43NjMzNSwyOC41OTY2OGMtMi45MTYyOCwwLjAwMDc3IC01LjU0MTMzLDEuNzY4NDEgLTYuNjM4NzEsNC40NzAzNWMtMS4wOTczNywyLjcwMTk0IC0wLjQ0ODI1LDUuNzk5MzcgMS42NDE2NCw3LjgzMzM2bDQ1LjA5OTYxLDQ1LjA5OTYxbC00NS4wOTk2MSw0NS4wOTk2MWMtMS44NzIyLDEuNzk3NTIgLTIuNjI2MzcsNC40NjY3NCAtMS45NzE2NCw2Ljk3ODIzYzAuNjU0NzMsMi41MTE0OSAyLjYxNjA0LDQuNDcyOCA1LjEyNzUzLDUuMTI3NTNjMi41MTE0OSwwLjY1NDczIDUuMTgwNzEsLTAuMDk5NDQgNi45NzgyMywtMS45NzE2NWw0NS4wOTk2MSwtNDUuMDk5NjFsNDUuMDk5NjEsNDUuMDk5NjFjMS43OTc1MiwxLjg3MjIzIDQuNDY2NzUsMi42MjY0MSA2Ljk3ODI1LDEuOTcxNjhjMi41MTE1LC0wLjY1NDcyIDQuNDcyODIsLTIuNjE2MDUgNS4xMjc1NSwtNS4xMjc1NWMwLjY1NDcyLC0yLjUxMTUgLTAuMDk5NDYsLTUuMTgwNzMgLTEuOTcxNjgsLTYuOTc4MjVsLTQ1LjA5OTYxLC00NS4wOTk2MWw0NS4wOTk2MSwtNDUuMDk5NjFjMi4xMTk2MiwtMi4wNjAzNSAyLjc1Njk0LC01LjIxMDY0IDEuNjA0ODYsLTcuOTMyODdjLTEuMTUyMDcsLTIuNzIyMjQgLTMuODU3MTksLTQuNDU3OTcgLTYuODExODksLTQuMzcwODRjLTEuODYxODksMC4wNTU0OCAtMy42MjkwNSwwLjgzMzYzIC00LjkyNzA4LDIuMTY5NmwtNDUuMDk5NjEsNDUuMDk5NjFsLTQ1LjA5OTYxLC00NS4wOTk2MWMtMS4zNDkyOCwtMS4zODY5OCAtMy4yMDIwMywtMi4xNjk0OCAtNS4xMzcwNCwtMi4xNjk2eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+) center/1.5em auto no-repeat;
    opacity: 0.8;
}

.form-check-input:checked[type=radio] {
    background-image: none;
}

.form-check-input:focus {
    box-shadow: none;
}



.btn-close:hover {
    opacity: 1;
}

.rbt-input-hint {
    margin-left: 10px !important;
}

.cross-delete {
    color: var(--danger)
}

.form-check-input:checked {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.form-control:disabled {
    background-color: #d4d4d4;
}

.link-help {
    cursor: pointer;
    color: var(--link);
}

.link-help:hover {
    cursor: pointer;
    color: var(--primary);
}

.cursor-not {
    cursor: not-allowed;
    color: var(--secondary);
}

.disabled-option {
    color: #6e7393;
}

.disabled-option:hover {
    color: white;
}

.dropdown-item:hover .disabled-option {
    color: white;
}