.options {
    display: flex;
    justify-content: flex-end;
}

.marginRight5px {
    margin-right: 5px;
}

.modal-body-input {
    padding-top: 0rem;
}


.noPaddingBottom {
    padding-bottom: 0px !important;
}

.labelFieldNewOperation {
    font-size: 12px !important;
    margin: 0rem 0rem 0.75rem 0rem !important;
}

.docsGray {
    width: 100%;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: #717171 !important;
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    background: rgb(237, 237, 237);
    color: rgb(176, 176, 176);
}

.color71 {
    color: #717171 !important;
}

.floatRight {
    float: right;
}

.cursorPointer {
    cursor: pointer;
}

.input-form-typeahead2 {
    padding-left: 1.8rem;
    border: 1px solid rgba(128, 128, 128, 0.671);
    line-height: 0;
    color: rgba(0, 0, 0, 0.686);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
    display: block;
}

.input-form-typeahead2:focus {
    border: 1px solid rgba(128, 128, 128, 0.671);
}

.form-select {

    background-image: url("data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iY29sb3I6IHJnYig4NywgODksIDk4KTsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1jaGV2cm9uLWRvd24iIHZpZXdCb3g9IjAgMCAxNiAxNiI+IDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIgZmlsbD0iIzU3NTk2MiI+PC9wYXRoPiA8L3N2Zz4=")
}



.docs {
    font-size: 0.8rem !important;
    font-family: Poppins, sans-serif !important;
    font-weight: bold;
    color: #575962 !important;
}

.docsElement {
    margin-bottom: 1.5rem
}

.docsTittle {
    color: var(--secondary);
    font-size: 0.9rem;
    font-weight: bold;
}

.docsSelect {
    min-width: 15rem;
    display: inline
}

.docsRow {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: 0rem !important;
}

.docsColumn {
    width: 50% !important;
    display: flex;
}

.docsColumn:last-child {
    justify-content: flex-end
}

.docsCabecera {
    width: 100%;
    color: var(--secondary);
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.docsInput {
    font-size: 0.8rem !important;
    line-height: 1.2rem !important;
    padding-left: 10px !important;
    text-indent: 0;
    background-color: white;
}

.btnX-document {
    border: 1px solid rgba(128, 128, 128, 0.671);
    border-radius: 5px;
    border-left: none;
    height: 2.105rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    background-color: white !important;
    color: #575962 !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    justify-content: flex-end;
}

.btnX-document:hover {
    border-color: rgba(128, 128, 128, 0.671) !important;
}

.btnX-document:active {
    border-color: rgba(128, 128, 128, 0.671) !important;
}

.upbtn {
    display: inline-block;
    font-weight: 400;
    font-size: 0.82rem !important;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin-right: 1rem;
}

.docsFich {
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: #717171 !important;
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    background: rgb(237, 237, 237);
    color: rgb(176, 176, 176);
}

.col-pers {
    width: 58.33%;
}

.fakeInput-document {
    text-indent: 10px;
    font-weight: 500 !important;
    border: 1px solid rgba(128, 128, 128, 0.671);
    border-radius: 5px;
    height: 2.105rem;
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.686);
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}